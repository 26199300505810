import commonHelper from '@/app/utility/common.helper.utility';
import ModulesList from '../../../../admin/responsibility/searchModule';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import party from '@/app/components/generalLedger/party';
import { ModelSelect } from 'vue-search-select';
export default {
  name: 'manualPayments',
  components: {
    DatePicker,
    party,
    ModulesList,
    ModelSelect
  },
  data() {
    return {
      loader: false,
      unsubscribe: null,
      showModal: false,
      selectedModule: {
        name: null,
        id: null
      },
      tab: null,
      payload: {},
      totalRows: null,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      paymentDocNum: null,
      paymentRefNo: null,
      advanceSearchForm: {
        instrumentStatusList: [
          {
            value: null,
            text: null
          }
        ]
      },
      selectedInstrumentStatus: {
        value: null,
        text: null
      },
      paymentMethod: {
        value: null,
        text: null
      },
      paymentMethodList: [],
      paymentBasedBanks: [],
      party: {
        value: null,
        text: null
      },
      showPartyModal: false,
      partyType: null,
      parent_value_set_id: null,
      leName: {
        value: null,
        text: null
      },
      ouName: {
        value: null,
        text: null
      },
      vsetCode: null,
      showValueSetModal: false,
      from_date_to_date: [],
      defaultValue:{
        value: null,
        text: null
      }
    };
  },
  mounted() {
    const advFormData = this.$store.state.manualPayments.advSearchFilters;
    if (advFormData) {
      this.setSearchItems(advFormData);
    }
  },
  methods: {
    searchManualPaymentParams() { // to get data of advance search
      const payload = {
        module_name: this.selectedModule.name,
        le_name: this.leName.text,
        searchParams: {
        module_id: this.selectedModule.id,
        le_id: this.leName.value,
        ou_id: this.ouName.value,
        payment_method_id: this.paymentMethod.value,
        party_id: this.party.value,
        party_type: this.partyType,
        doc_num: this.paymentDocNum,
        payment_ref_no: this.paymentRefNo,
        instrument_status: this.selectedInstrumentStatus.value,
        payment_from_date: commonHelper.formattedDate(
          this.from_date_to_date[0]
        ),
        payment_to_date: commonHelper.formattedDate(this.from_date_to_date[1])}
      };
      this.$emit('advanceSearch', payload);
      this.$store.dispatch('manualPayments/saveAdvSearchFilters', this);
      // this.$store.state.manualPayments.manualPayments.adv_search = this;
    },
    getPaymentMethodsList() { // to get payment method list
      const payload = {
        module_id: this.selectedModule.id == 22 ? 20 : this.selectedModule.id
      };

      this.loader = true;
      this.$store
        .dispatch('bankMpngMode/getPaymentMethods', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            const typeList = results.map(type => {
              return {
                value: type.party_payment_method_id,
                text: type.payment_methods_name
              };
            });
            this.paymentMethodList = typeList;
            this.paymentBasedBanks = results;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openShowModal(flag, component) {
      this.showModal = flag;
      this.tab = component;
      // this.setChildName(this.tab);
    },

    selectedModuleData(module) { // to select module
      this.selectedModule.name = module.module_name;
      this.selectedModule.id = module.module_id;
      this.showModal = false;

      this.getPaymentMethodsList();
    },

    selectManual(item) {
      this.selectedBatchDetails = Object.assign({}, item);
      this.showBatchBtn = true;
    },
    getSelectedPartyCustomer(item) { // to select customer
      this.party.text = item.party_name;
      this.party.value = item.party_id;
      this.partyType = item.party_type;
      this.showPartyModal = false;
    },
    showHidePartyModal(flag) { // to open party modal
      this.showPartyModal = flag;
      this.setCustCode = setTimeout(() => {
        this.eventBus.$emit('userType', 'CUST');
      }, 0);
    },
    openValueSetModal(vsetCode) { // to open valueset
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id=null;
      if (vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.leName.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) { // to select valueset
      if(this.vsetCode === appStrings.VALUESETTYPE.MANUAL_MODULE_VSET){
        this.selectedModule.name = item.value_code;
        this.selectedModule.id = item.value_set_dtl_id;
        this.getPaymentMethodsList();
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.leName = {
          value: item.org_id,
          text: item.org_name
        };
        this.ouName = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.ouName = {
          value: item.org_id,
          text: item.org_name
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.INSTRUMENT_STATUS) {
        this.selectedInstrumentStatus = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
    },
    closeValueSetModal() { // to close valueset
      this.showValueSetModal = false;
    },
    clearFilters() { // to clear filters
      this.leName.text=null;
      this.leName.value=null;
      this.ouName.text=null;
      this.ouName.value=null;
      this.party.text=null;
      this.party.value=null;
      this.selectedModule.id=null;
      this.selectedModule.name=null;
      this.paymentMethod.text=null;
      this.paymentMethod.value=null;
      this.partyType = null;
      this.from_date_to_date = [];
      this.paymentDocNum = null;
      this.paymentRefNo = null;
      this.selectedInstrumentStatus = [];
      this.parent_value_set_id = null;
      this.$store.dispatch('manualPayments/saveAdvSearchFilters', null);
      // this.$store.state.manualPayments.manualPayments.adv_search = null;
    },
    setSearchItems(item) {
      this.selectedModule = item.selectedModule;
      this.leName = item.leName;
      this.ouName = item.ouName;
      this.paymentMethod = item.paymentMethod;
      this.party = item.party;
      this.partyType = item.partyType;
      this.paymentDocNum = item.paymentDocNum;
      this.paymentRefNo = item.paymentRefNo;
      this.selectedInstrumentStatus = item.selectedInstrumentStatus;
      this.from_date_to_date = item.from_date_to_date;
      this.paymentMethodList = item.paymentMethodList;
      this.advanceSearchForm.instrumentStatusList = item.advanceSearchForm.instrumentStatusList;

    },
    clearVsetValues(vsetCode){
      if (vsetCode === this.selectedModule.id) {
        this.selectedModule = {
          name: null,
          id: null
        };
      }
      switch(vsetCode){
        case appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST:
          this.leName = this.defaultValue
          break;
        case appStrings.VALUESETTYPE.OU_LIST:
          this.ouName = this.defaultValue
          break;
        case appStrings.VALUESETTYPE.INSTRUMENT_STATUS:
          this.selectedInstrumentStatus = this.defaultValue
          break;
        }
    }
  }
};

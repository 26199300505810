import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';
import ModulesList from '../../../../admin/responsibility/searchModule';
import commonHelper from '@/app/utility/common.helper.utility';
import { ModelSelect } from 'vue-search-select';
import party from '@/app/components/generalLedger/party';
import Currency from '../../../../admin/currency';
import { required } from 'vuelidate/lib/validators';
import manualPaymentInvoice from './manualPaymentInvoice';
import { format } from 'date-fns';
export default {
  name: 'addManualPayments',
  components: {
    DatePicker,
    ModulesList,
    Currency,
    party,
    ModelSelect,
    manualPaymentInvoice
  },
  watch: {
    'paymentMethod.text'() {
      this.setBankDetails();
    },
    currentPage: function() {
      this.getManualPaymentInvoice();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getManualPaymentInvoice();
    }
  },
  props: ['manualPaymentDetails'],
  data() {
    return {
      unsubscribe: null,
      loader: false,
      showAlert: false,
      isSuccess: false,
      showModal: false,
      responseMsg: '',
      totalRows: null,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      tab: null,
      vsetCode: null,
      parent_value_set_id: null,
      editMode: false,
      showValueSetModal: false,
      defaultNameValue: {
        name: null,
        id: null
      },
      legalEntity: {
        text: null,
        value: null
      },
      operatingUnit: {
        text: null,
        value: null
      },
      insStatus: {
        text: null,
        value: null
      },
      selectedModule: {
        name: null,
        id: null
      },
      project: {
        value: null,
        text: null
      },
      payGroup: {
        value: null,
        text: null
      },
      paymentMode: {
        value: null,
        text: null
      },
      scheduleType: {
        value: null,
        text: null
      },
      paymentType: {
        value: null,
        text: null
      },
      paymentPriority: 99,
      partyType: null,
      currency: {
        name: 'Indian Rupee',
        id: 68
      },
      exchangeRate: 1,
      exchangeRateType: 'CORPORATE',
      partySiteAddress: null,
      dueDate: [],
      paymentDate: format(new Date(), 'dd-MMM-yyyy'),
      startDate: null,
      endDate: null,
      voidDate: null,
      voidGlDate: null,
      openCurrencyModal: false,
      manualPaymentData: [
        {
          invoice_num: null,
          invoice_date: null,
          invoice_amount: null,
          schedule_num: null,
          schedule_line_type_vset: null,
          schedule_amt: null,
          outstanding_amt: null,
          paid_in_this_payment: null
        }
      ],
      manualPaymentFields: [
        // {
        //   key: 'add',
        //   stickyColumn: true
        // },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'trx_code'
        },
        {
          key: 'invoice_num',
          label: 'Invoice Number'
        },
        {
          key: 'paid_in_this_payment',
          label: 'Paid in this Payment'
        },
        {
          key: 'invoice_date'
        },
        {
          key: 'invoice_amount'
        },
        {
          key: 'schedule_num',
          label: 'Schedule Number'
        },
        {
          key: 'schedule_line_type_vset',
          label: 'Schedule Line Type'
        },
        {
          key: 'schedule_amt',
          label: 'Schedule Amount'
        },
        {
          key: 'outstanding_amt',
          label: 'Outstanding Amount'
        }
      ],
      party: {
        name: null,
        id: null
      },
      partySite: {
        name: null,
        id: null
      },
      showPartyModal: false,
      showCustomerSiteModal: false,
      gridDataSite: [],
      gridFieldsSite: [
        {
          key: 'site_name'
        },
        {
          key: 'site_type'
        },
        {
          key: 'bill_to_address',
          label: 'Site Address'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'gstn',
          label: 'GSTN'
        }
      ],
      paymentMethod: {
        value: null,
        text: null
      },
      paymentMethodList: [],
      paymentBasedBanks: [],
      showManualPaymentInvoice: false,
      searchValue: {
        module_id: null,
        prj_id: null,
        le_id: null,
        ou_id: null,
        pay_group_vset: null,
        payment_mode_vset: null,
        schedule_line_type_vset: null,
        payment_priority: null,
        due_date_from: null,
        due_date_to: null,
        party_id: null,
        party_site_id: null,
        party_type: null
      },
      apPaymentBatchId: null,
      payRefNo: null,
      docNo: null,
      payAmount: null,
      payBatchNo: null,
      extBankAcc: {
        text: null,
        value: null
      },
      apPaymentHdrId: null,
      statusFlag: false,
      paymentHdrId: '',
      paySeries: {
        value: null,
        text: null
      },
      depVsetParam: null,
      accountName: null,
      remarks: null
    };
  },
  validations: {
    selectedModule: {
      name: { required }
    },
    project: {
      text: { required }
    }
    // paySeries: { text: { required } }
  },
  mounted() {
    if (this.manualPaymentDetails) {
      this.paymentHdrId = this.manualPaymentDetails.ap_payment_hdr_id;
      this.apPaymentBatchId = this.manualPaymentDetails.ap_payment_batch_id;
      this.getManualPaymentInvoiceById(this.apPaymentBatchId);
    } else {
      this.editMode = true;
    }
    this.manualPaymentData = [];
    this.setDefaultTrasferStatus();
    this.setDefaultPaymentType();
    this.eventBus.$on('getCurrency', getCurrency => {
      this.currency.name = getCurrency.currency;
      this.currency.id = getCurrency.currency_id;
      if (this.currency.id === 68) {
        this.exchangeRate = 1;
      } else {
        this.exchangeRate = null;
      }
      this.openCurrencyModal = false;
    });
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          this.tempFunction();
        }
      }
    });
  },
  methods: {
    tempFunction() {
      this.validatePaymentAmount();
    },
    // totalPayAmt() {
    //   const result = this.manualPaymentData
    //     .filter(elem => elem.paid_in_this_payment)
    //     .map(data => data.paid_in_this_payment);
    //   const total = commonHelper.calculateTotal(result);
    //   this.payAmount = parseFloat(total).toFixed(2);
    // },
    totalPayAmt() {
      let sum = 0;
      this.manualPaymentData.forEach(data => {
        if (data.paid_in_this_payment) {
          sum += parseFloat(data.paid_in_this_payment);
        }
      });
      this.payAmount = parseFloat(sum).toFixed(2);
      this.payAmount = this.payAmount == '-0.00' ? +'0.00' : this.payAmount;
    },
    // validatePaymentAmount() {
    //   // let totalAmount = 0;
    //   // this.manualPaymentData.forEach(elem => {
    //   //   totalAmount += elem.paid_in_this_payment;
    //   // });
    //   const result = this.manualPaymentData.map(
    //     elem => elem.paid_in_this_payment
    //   );
    //   const total = commonHelper.calculateTotal(result);
    //   if (parseInt(total) == parseInt(this.payAmount)) {
    //     this.addManualPaymentInvoice();
    //   } else {
    //     alert('Total Paid in this Payment should equal to payment amount');
    //   }
    // },
    validatePaymentAmount() {
      let sum = 0;
      this.manualPaymentData.forEach(element => {
        sum += parseFloat(element.paid_in_this_payment);
        this.totalAmt = parseFloat(sum).toFixed(2);
      });

      if (parseInt(this.totalAmt) == parseInt(this.payAmount)) {
        this.addManualPaymentInvoice();
      } else {
        alert('Total Paid in this Payment should equal to payment amount');
      }
    },
    checkDuplicateInvoice(array) {
      // const uniqueArray = [...new Set(array.map(JSON.stringify))].map(
      //   JSON.parse
      // );
      // return uniqueArray;
      const uniqueArr = Object.values(
        array.reduce((acc, obj) => {
          const key = `${obj.invoice_num}-${obj.schedule_num}-${obj.schedule_line_type_vset}`;
          if (!acc[key]) {
            acc[key] = obj;
          }
          return acc;
        }, {})
      );
      return uniqueArr;
    },
    validatePayAmount(payType) {
      if (payType === 'NORMAL' && this.payAmount <= 0) {
        alert('amount should be in postive');
        this.payAmount = null;
      }
    },
    validateAmount(rowData) {
      const tempVal = parseInt(rowData.paid_in_this_payment);
      if (tempVal > 0) {
        if (tempVal >= parseInt(rowData.outstanding_amt)) {
          rowData.paid_in_this_payment = null;
          alert('Paid Amount Should not be greater than Outstanding Amount');
        }
      } else {
        if (tempVal <= parseInt(rowData.outstanding_amt)) {
          rowData.paid_in_this_payment = null;
          alert('Paid Amount Should not be greater than Outstanding Amount');
        }
      }
      this.totalPayAmt();
    },
    getManualPaymentInvoice() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          module_id: this.selectedModule.id,
          le_id: this.legalEntity.value,
          ou_id: this.operatingUnit.value,
          prj_id: this.project.value,
          party_id: this.party.id,
          party_type: this.partyType,
          party_site_id: this.partySite.id
        };
        this.loader = true;
        this.$store
          .dispatch('manualPayments/getManualPaymentInvoice', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.manualPaymentData = response.data.data.page;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    checkMandatoryParams() {
      if (
        this.selectedModule.id &&
        this.project.value &&
        this.legalEntity.value &&
        this.operatingUnit.value &&
        this.paymentMode.value &&
        this.partyType &&
        this.party.id &&
        this.partySite.id &&
        this.paymentType.value &&
        this.partySiteAddress
      ) {
        if (this.paymentType.value === 'NORMAL') {
          if (this.paySeries.value) {
            this.showHideManualPaymentInvoiceModal(true);
          } else {
            alert('Please Select Payment Series');
          }
        }
        if (this.paymentType.value === 'REFUND') {
          if (this.payRefNo) {
            this.showHideManualPaymentInvoiceModal(true);
          } else {
            alert('Please Select Payment Ref No.');
          }
        }
        // this.showHideManualPaymentInvoiceModal(true);
      } else {
        alert('Please Select Mandatory Parameters');
      }
    },
    showHideManualPaymentInvoiceModal(flag) {
      this.showManualPaymentInvoice = flag;
      this.searchValue.le_id = this.legalEntity.value;
      this.searchValue.ou_id = this.operatingUnit.value;
      this.searchValue.module_id = this.selectedModule.id;
      this.searchValue.prj_id = this.project.value;
      this.searchValue.pay_group_vset = this.payGroup.value;
      this.searchValue.payment_mode_vset = this.paymentMode.value;
      this.searchValue.payment_type = this.paymentType.value;
      // this.searchValue.schedule_line_type_vset = this.scheduleType.value;
      // this.searchValue.payment_priority = this.paymentPriority;
      // this.searchValue.due_date_from = commonHelper.formattedDate(
      //   this.dueDate[0]
      // );
      // this.searchValue.due_date_to = commonHelper.formattedDate(
      //   this.dueDate[1]
      // );
      this.searchValue.party_type = this.partyType;
      this.searchValue.party_id = this.party.id;
      this.searchValue.party_site_id = this.partySite.id;
      this.searchValue.external_bank_account_id = this.extBankAcc.value;
    },
    getManualPaymentInvoiceById(apPaymentBatchId) {
      const payload = {
        apPaymentBatchId: apPaymentBatchId,
        payment_hdr_id: this.paymentHdrId
          ? this.paymentHdrId
          : this.apPaymentHdrIdByBatch
      };
      this.loader = true;
      this.$store
        .dispatch('manualPayments/getManualPaymentInvoiceById', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.selectedModule.name = response.data.data.module;
            this.selectedModule.id = response.data.data.module_id;
            this.project.text = response.data.data.project_name;
            this.project.value = response.data.data.project_id;
            this.legalEntity.text = response.data.data.le_name;
            this.legalEntity.value = response.data.data.le_id;
            this.operatingUnit.text = response.data.data.ou_name;
            this.operatingUnit.value = response.data.data.ou_id;
            this.paymentMode.text =
              response.data.data.payment_mode_vset_meaning;
            this.paymentMode.value = response.data.data.payment_mode_vset;
            this.partyType = response.data.data.party_type_meaning;
            this.party.name = response.data.data.party_name;
            this.party.id = response.data.data.party_id;
            this.partySite.name = response.data.data.party_site;
            this.partySite.id = response.data.data.party_site_id;
            this.partySiteAddress = response.data.data.party_site_address;
            this.paymentDate = response.data.data.payment_date;
            this.paymentMethod.text = response.data.data.payment_methods_name;
            this.paymentMethod.value = response.data.data.payment_method_id;
            this.paymentType.text = response.data.data.payment_type_meaning;
            this.paymentType.value = response.data.data.payment_type;
            this.paySeries.text = response.data.data.payment_series_name;
            this.paySeries.value = response.data.data.payment_series_id;
            this.insStatus.text =
              response.data.data.instrument_status_vset_meaning;
            this.insStatus.value = response.data.data.instrument_status_vset;
            this.currency.name = response.data.data.currency;
            this.currency.id = response.data.data.currency_id;
            this.exchangeRate = response.data.data.exchange_rate;
            this.payRefNo = response.data.data.payment_ref_no;
            this.docNo = response.data.data.document_num;
            this.payBatchNo = response.data.data.ap_payment_batch_name;
            this.payAmount = response.data.data.payment_amt;
            this.extBankAcc.text = response.data.data.bank_account;
            this.extBankAcc.value = response.data.data.external_bank_account_id;
            this.manualPaymentData = response.data.data.ap_payments_hdr_temp;
            this.accountName = response.data.data.acc_name;
            this.remarks = response.data.data.remarks;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addManualPaymentInvoice() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const invoiceDetails = this.manualPaymentData.map(elem => {
          return {
            ap_payment_hdr_id: elem.ap_payment_hdr_id
              ? elem.ap_payment_hdr_id
              : 0,
            ap_payment_dtl_id: elem.ap_payment_dtl_id,
            ap_invoice_id: elem.ap_invoice_id,
            ap_invoice_schedule_id: elem.invoice_schedule_id,
            amount: elem.paid_in_this_payment,
            original_amount: elem.outstanding_amt
          };
        });
        const payload = {
          ap_payments_batch_id: 0,
          payment_mode_vset: this.paymentMode.value,
          payment_date: this.paymentDate,
          ou_id: this.operatingUnit.value,
          le_id: this.legalEntity.value,
          pay_group_vset: this.payGroup.value,
          party_id: this.party.id,
          party_type: this.partyType,
          // schedule_type: this.scheduleType.value,
          // payment_priority: this.paymentPriority,
          payment_ref_no: this.payRefNo,
          currency_id: this.currency.id,
          module_id: this.selectedModule.id,
          exchange_rate: this.exchangeRate,
          project_id: this.project.value,
          party_site_id: this.partySite.id,
          payment_method_id: this.paymentMethod.value,
          payment_type: this.paymentType.value,
          // due_date_from: commonHelper.formattedDate(this.dueDate[0]),
          // due_date_to: commonHelper.formattedDate(this.dueDate[1]),
          instrument_status_vset: this.insStatus.value,
          payment_series_id: this.paySeries.value,
          external_bank_account_id: this.extBankAcc.value
            ? this.extBankAcc.value
            : null,
          ap_payments_hdr_temp: invoiceDetails ? invoiceDetails : null,
          remarks: this.remarks
        };
        this.loader = true;
        this.$store
          .dispatch('manualPayments/addManualPaymentInvoice', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.apPaymentBatchId = response.data.data.ap_payment_batch_id;
              this.apPaymentHdrId =
                response.data.data.payment_hdr_id_temp_for_error;
              this.payRefNo = response.data.data.payment_ref_no;
              this.docNo = response.data.data.document_num;
              this.editMode = false;
              this.isSuccess = true;
              this.showAlert = true;
              this.responseMsg = response.data.message;
              this.validateManualPaymentInvoice();
              this.statusFlag = true;
            } else {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      }
    },
    getPaymentHdrIdByBatch() {
      const payload = {
        apPaymentBatchId: this.apPaymentBatchId
      };
      this.loader = true;
      this.$store
        .dispatch('manualPayments/getPaymentHdrIdByBatch', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.apPaymentHdrIdByBatch = response.data.data.ap_payment_hdr_id;
            this.getManualPaymentInvoiceById(this.apPaymentBatchId);
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
        });
    },
    deleteManualPaymentsCancel() {
      const payload = {
        payment_batch_id: this.apPaymentBatchId
      };
      this.loader = true;
      this.$store
        .dispatch('manualPayments/deleteManualPaymentsCancel', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.showAlert = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      this.depVsetParam = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.legalEntity.value;
      }
      if (
        this.vsetCode ===
        appStrings.VALUESETTYPE.MANUAL_PAYMENT_EXTERNAL_BANK_AC
      ) {
        this.parent_value_set_id = this.partySite.id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.PAYMENT_SERIES_AP) {
        this.parent_value_set_id = this.paymentMode.value;
        this.depVsetParam = this.accountId;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.MANUAL_MODULE_VSET) {
        this.selectedModule.name = item.value_code;
        this.selectedModule.id = item.value_set_dtl_id;
        this.project = { value: null, text: null };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST ||
        this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT
      ) {
        this.project = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.legalEntity = {
          value: item.le_id,
          text: item.le_name
        };
        this.operatingUnit = {
          value: item.ou_id,
          text: item.ou_name
        };
        this.getPaymentMethodsList();
      } else if (this.vsetCode === appStrings.VALUESETTYPE.INSTRUMENT_STATUS) {
        this.insStatus = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.PAYMENT_TYPE_VSET) {
        this.paymentType = {
          value: item.value_code,
          text: item.value_meaning
        };
        this.payRefNo = null;
        this.paySeries = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PARTY_TYPE) {
        this.partyType = item.value_code;
        this.party = {
          name: null,
          id: null
        };
        this.partySite = {
          name: null,
          id: null
        };
        this.partySiteAddress = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.PAY_GROUP_TYPE) {
        this.payGroup = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.PAYMENT_MODE) {
        this.paymentMode = {
          value: item.value_code,
          text: item.value_meaning
        };
        this.getPaymentMethodsList();
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.SCHEDULE_LINE_TYPE_VSET
      ) {
        this.scheduleType = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (
        this.vsetCode ===
        appStrings.VALUESETTYPE.MANUAL_PAYMENT_EXTERNAL_BANK_AC
      ) {
        this.extBankAcc = {
          value: item.value_set_dtl_id,
          text: item.account_num
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.PAYMENT_SERIES_AP) {
        this.paySeries.text = item.value_code;
        this.paySeries.value = item.value_set_dtl_id;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    setBankDetails() {
      if (this.paymentBasedBanks && this.paymentBasedBanks.length > 0) {
        let paymentBankDetail = this.paymentBasedBanks.find(key => {
          if (key.payment_methods_name === this.paymentMethod.text) {
            return key;
          }
        });
        this.accountId = paymentBankDetail.bank_account_id;
        this.accountName = paymentBankDetail.account_name;
      }
    },
    setChildName(tab) {
      //method for child modal name
      if (tab === 'ModulesList') {
        return (this.childName = 'Modules List');
      }
    },
    openShowModal(flag, component) {
      this.showModal = flag;
      this.tab = component;
      this.setChildName(this.tab);
    },
    selectedModuleData(module) {
      this.selectedModule.name = module.module_name;
      this.selectedModule.id = module.module_id;
      this.project = { value: null, text: null };
      this.showModal = false;
    },
    openModuleBasedProject(moduleName) {
      if (moduleName === 'FMS') {
        this.openValueSetModal(appStrings.VALUESETTYPE.FMS_PROJECT);
      } else if (moduleName === 'Lease Management') {
        this.openValueSetModal(appStrings.VALUESETTYPE.LEASE_PROJECT_LIST);
      } else if (moduleName === 'Receivables') {
        this.openValueSetModal(appStrings.VALUESETTYPE.LEASE_PROJECT_LIST);
      } else {
        alert('Please Select Module First');
      }
    },
    openCloseCurrency(flag) {
      this.$store.dispatch('party/setUnsetPartyId', 1);
      this.openCurrencyModal = flag;
    },
    showHidePartyModal(flag) {
      if (this.partyType) {
        this.showPartyModal = flag;
        if (flag) {
          if (this.partyType === 'VENDOR') {
            this.setPartyTimeout = setTimeout(() => {
              this.eventBus.$emit('userType', 'VEND');
            }, 0);
          } else if (this.partyType === 'CUSTOMER') {
            this.setPartyTimeout = setTimeout(() => {
              this.eventBus.$emit('userType', 'CUST');
            }, 0);
          }
        }
      } else {
        alert('Please select party type!');
      }
    },
    getSelectedPartyCustomer(item) {
      this.party.name = item.party_name;
      this.showPartyModal = false;
      this.partySite = {
        id: null,
        name: null
      };
      if (this.partyType === 'VENDOR') {
        this.getVendorIdByPartyId(item.party_id);
      } else if (this.partyType === 'CUSTOMER') {
        this.getCustomerByPartyId(item.party_id);
      }
    },
    getVendorIdByPartyId(partyId) {
      this.$store
        .dispatch('leaseSchedule/getVendorIdByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.party.id = response.data.data;
            this.getVendorSites();
          }
        });
    },
    getCustomerByPartyId(partyId) {
      this.$store
        .dispatch('leases/getCustomerByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.party.id = response.data.data.customer_id;
            this.getCustomerSiteByPartyId();
          }
        });
    },
    showHideCustomerSiteModal(flag) {
      if (this.party.id && this.partyType) {
        this.showCustomerSiteModal = flag;
      } else if (!this.partyType) {
        alert('Please select party type!');
      } else if (!this.party.id) {
        alert('Please select party!');
      }
    },
    getVendorSites() {
      const payload = {
        vendor_id: this.party.id,
        ou_id: this.operatingUnit.value
      };
      this.gridDataSite = [];
      this.$store
        .dispatch('party/getVendorSiteByOu', payload)
        .then(response => {
          this.gridDataSite = response.data.data.vendor_sites;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getCustomerSiteByPartyId() {
      const payload = {
        customer_id: this.party.id,
        ou_id: this.operatingUnit.value
      };
      this.gridDataSite = [];
      this.$store
        .dispatch('party/getCustomerSiteByOu', payload)
        .then(response => {
          this.gridDataSite = response.data.data.customer_site_lists;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedSite(item) {
      this.partySite.name = item.site_name;
      this.partySiteAddress = item.bill_to_address;
      if (this.partyType === 'VENDOR') {
        this.partySite.id = item.vendor_site_id;
      } else if (this.partyType === 'CUSTOMER') {
        this.partySite.id = item.customer_site_id;
      }
      this.showCustomerSiteModal = false;
    },
    getPaymentMethodsList() {
      // To open valueset payment method
      const payload = {
        module_id: this.selectedModule.id == 22 ? 20 : this.selectedModule.id,
        le_id: this.legalEntity.value,
        project_id: this.project.value,
        receipt_mode_vset_code: this.paymentMode.value
      };
      this.loader = true;
      this.$store
        .dispatch('bankMpngMode/getPaymentMethods', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            const typeList = results.map(type => {
              return {
                value: type.party_payment_method_id,
                text: type.payment_methods_name
              };
            });
            this.paymentMethodList = typeList;
            this.paymentBasedBanks = results;
            this.setBankDetails();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedInvoice(item) {
      const arr = this.manualPaymentData.concat(item);
      const removeDuplicate = this.checkDuplicateInvoice(arr);
      this.manualPaymentData = removeDuplicate;
      this.showManualPaymentInvoice = false;
      this.totalPayAmt();
    },
    addNewRow() {
      if (this.editMode) {
        this.manualPaymentData.push({
          invoice_number: null,
          invoice_date: null,
          invoice_amount: null,
          schedule_number: null,
          schedule_line_type: null,
          schedule_amount: null,
          outstanmanuading_amount: null,
          payment: null
        });
      }
    },
    removeRow(index) {
      this.manualPaymentData.splice(index, 1);
      this.totalPayAmt();
    },
    setDefaultBankAccount() {
      const bankAccount = commonHelper.getValuesetData(
        appStrings.VALUESETTYPE.MANUAL_PAYMENT_EXTERNAL_BANK_AC
      );
      bankAccount.then(key => {
        this.extBankAcc.text = key[0].account_num;
        this.extBankAcc.value = key[0].value_set_dtl_id;
      });
    },
    setDefaultTrasferStatus() {
      const instrumentStatus = commonHelper.getValuesetData(
        appStrings.VALUESETTYPE.INSTRUMENT_STATUS,
        'NEGOTIABLE'
      );
      instrumentStatus.then(key => {
        this.insStatus.text = key[0].value_meaning;
        this.insStatus.value = key[0].value_code;
      });
    },
    setDefaultPaymentType() {
      const paymentType = commonHelper.getValuesetData(
        appStrings.VALUESETTYPE.PAYMENT_TYPE_VSET,
        'NORMAL'
      );
      paymentType.then(key => {
        this.paymentType.text = key[0].value_meaning;
        this.paymentType.value = key[0].value_code;
      });
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.selectedModule.id) {
        this.selectedModule = {
          name: null,
          id: null
        };
        this.project = { value: null, text: null };
        this.legalEntity = { value: null, text: null };
        this.operatingUnit = { value: null, text: null };
      } else if (vsetCode === this.project.value) {
        this.project = { value: null, text: null };
        this.legalEntity = { value: null, text: null };
        this.operatingUnit = { value: null, text: null };
      } else if (vsetCode === this.partyType) {
        this.partyType = null;
        this.party = {
          name: null,
          id: null
        };
        this.partySite = {
          name: null,
          id: null
        };
        this.partySiteAddress = null;
      } else if (vsetCode === this.insStatus.value) {
        this.insStatus = { value: null, text: null };
      } else if (vsetCode === this.paymentType.value) {
        this.paymentType = { value: null, text: null };
      } else if (vsetCode === this.payGroup.value) {
        this.payGroup = { value: null, text: null };
      } else if (vsetCode === this.paymentMode.value) {
        this.paymentMode = { value: null, text: null };
      } else if (vsetCode === this.scheduleType.value) {
        this.scheduleType = { value: null, text: null };
      } else if (vsetCode === this.extBankAcc.value) {
        this.extBankAcc = { value: null, text: null };
      } else if (vsetCode === this.paySeries.value) {
        this.paySeries = { value: null, text: null };
      } else if (vsetCode === this.party.id) {
        this.party = {
          name: null,
          id: null
        };
        this.partySite = {
          name: null,
          id: null
        };
        this.partySiteAddress = null;
      } else if (vsetCode === this.partySite.id) {
        this.partySite = {
          name: null,
          id: null
        };
        this.partySiteAddress = null;
      } else if (vsetCode === this.currency.id) {
        this.currency = {
          name: null,
          id: null
        };
        this.exchangeRate = null;
      }
    },
    validateManualPaymentInvoice() {
      const payload = {
        payment_batch_id: this.apPaymentBatchId
      };
      this.loader = true;
      this.$store
        .dispatch('manualPayments/validateManualPaymentInvoice', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.errorManualPaymentInvoice();
            this.editMode = false;
            this.isSuccess = true;
            this.showAlert = true;
            this.responseMsg = response.data.message;
          } else {
            this.deleteManualPaymentsCancel();
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
          this.getManualPaymentInvoiceById(this.apPaymentBatchId);
        });
    },
    errorManualPaymentInvoice() {
      const payload = {
        payment_hdr_id: this.apPaymentHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('manualPayments/errorManualPaymentInvoice', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.confirmManualPaymentInvoice();
            this.editMode = false;
            this.isSuccess = true;
            this.showAlert = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    confirmManualPaymentInvoice() {
      const payload = {
        payment_batch_id: this.apPaymentBatchId
      };
      this.loader = true;
      this.$store
        .dispatch('manualPayments/confirmManualPaymentInvoice', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.getPaymentHdrIdByBatch();
            // this.getManualPaymentInvoiceById(this.apPaymentBatchId);
            this.editMode = false;
            this.isSuccess = true;
            this.showAlert = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.isSuccess = false;
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};

import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'addManualPayments',
  components: {},
  props: ['searchValue'],
  watch: {
    currentPage: function() {
      this.getManualPaymentInvoice();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getManualPaymentInvoice();
    },
    selectAllCheckBox: function() {
      this.checkUncheckAllSelectBox();
      this.calculateTotalOutstandingAmt();
    }
  },
  data() {
    return {
      unsubscribe: null,
      loader: false,
      showAlert: false,
      isSuccess: false,
      showModal: false,
      responseMsg: '',
      totalRows: null,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      manualPaymentData: [],
      manualPaymentFields: [
        {
          key: 'active',
          label: 'Select',
          variant: 'primary',
          stickyColumn: true
        },
        {
          key: 'invoice_num'
        },
        {
          key: 'paid_in_this_payment',
          label: 'Paid in this Payment'
        },
        {
          key: 'invoice_date'
        },
        {
          key: 'invoice_amount'
        },
        {
          key: 'schedule_num',
          label: 'Schedule Number'
        },
        {
          key: 'schedule_line_type_vset',
          label: 'Schedule Line Type'
        },
        {
          key: 'schedule_amt',
          label: 'Schedule Amount'
        },
        {
          key: 'outstanding_amt',
          label: 'Outstanding Amount'
        }
      ],
      invoiceNumber: null,
      totalOutstandingAmt: 0,
      selectAllCheckBox: false
    };
  },
  mounted() {
    this.getManualPaymentInvoice();
  },
  methods: {
    getManualPaymentInvoice() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        module_id: this.searchValue.module_id,
        le_id: this.searchValue.le_id,
        ou_id: this.searchValue.ou_id,
        prj_id: this.searchValue.prj_id,
        // pay_group_vset: this.searchValue.pay_group_vset,
        payment_mode_vset: this.searchValue.payment_mode_vset,
        // schedule_line_type_vset: this.searchValue.schedule_line_type_vset,
        // payment_priority: this.searchValue.payment_priority,
        // due_date_from: this.searchValue.due_date_from,
        // due_date_to: this.searchValue.due_date_to,
        party_type: this.searchValue.party_type,
        party_id: this.searchValue.party_id,
        party_site_id: this.searchValue.party_site_id,
        payment_type: this.searchValue.payment_type,
        invoice_num: this.invoiceNumber,
        external_bank_account_id: this.searchValue.external_bank_account_id
      };
      this.loader = true;
      this.$store
        .dispatch('manualPayments/getManualPaymentInvoice', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.manualPaymentData = response.data.data.page;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedInvoiceForManualPayments() {
      const selectedInvoiceManual = this.manualPaymentData.filter(item => {
        if (item.active) {
          return item;
        }
      });
      if (selectedInvoiceManual.length) {
        this.$emit('selectedInvoice', selectedInvoiceManual);
      } else {
        alert('Please Select Invoice');
      }
    },
    checkUncheckAllSelectBox() {
      this.manualPaymentData = this.manualPaymentData.map(data => {
        data.active = this.selectAllCheckBox;
        return data;
      });
    },
    calculateTotalOutstandingAmt() {
      let totalAmount=0;
      this.manualPaymentData.map(item => {
        if(item.active){
          totalAmount += item.outstanding_amt;
        }
        this.totalOutstandingAmt= totalAmount.toFixed(2);
      });
    }
  }
};
